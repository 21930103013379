import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import moment from 'moment';
import { PAGES } from 'config';
import {
  Banner,
  Modal,
  FormSelect,
  withFormGroup,
} from 'components';
import { numberToCurrency, getRate, calculatePriceWithRate } from 'utilities';
import SVGArrow from '../../../public/svg/white-large-arrow.svg';

const DEPARTING_OPTIONS = {
  15: {
    title: 'Next 15 days',
    value: 15,
  },
  30: {
    title: 'Next 30 days',
    value: 30,
  },
  45: {
    title: 'Next 45 days',
    value: 45,
  },
  60: {
    title: 'Next 60 days',
    value: 60,
  },
  90: {
    title: 'Next 90 days',
    value: 90,
  },
  1000: {
    title: 'All departures',
    value: 1000,
  },
};

const TYPE_OPTIONS = {
  Adventure: {
    title: 'Adventure',
    value: 'Adventure',
  },
  'Haka Plus': {
    title: 'Haka Plus',
    value: 'Haka Plus',
  },
  Snow: {
    title: 'Snow',
    value: 'Snow',
  },
};

const FIELDS = {
  DEPARTING: {
    ID: 'DepartingIn',
    OPTIONS: Object.values(DEPARTING_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
    })).reverse(),
  },
  FROM: {
    ID: 'FromLocation',
  },
  TYPE: {
    ID: 'TourType',
    OPTIONS: Object.values(TYPE_OPTIONS).map((option, i) => ({
      ...option,
      id: i,
    })),
  },
};

const filterToMostRecentNDays = ({ daysCount, list }) => {
  const todayPlusSomeDays = moment(new Date())
    .add({ days: daysCount })
    .toDate();

  return list.filter(
    i => moment(i.date, 'YYYY-MM-DD').toDate() < todayPlusSomeDays,
  );
};

const formatDate = str => moment(str, 'YYYY-MM-DD').format('DD MMM YY');

const generateOptions = (arr) => {
  const uniqueValues = Array.from(new Set(arr));
  const options = uniqueValues.map((i, j) => Object.assign({}, { title: i, value: i, id: j }));
  return options;
};

class DiscountsList extends PureComponent {
  /**
   * Initial state values
   */
  static values() {
    const { DEPARTING, FROM, TYPE } = FIELDS;

    return {
      [DEPARTING.ID]: '1000',
      [FROM.ID]: '',
      [TYPE.ID]: '',
    };
  }

  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.displayMapModal = this.displayMapModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      values: this.constructor.values(),
      displayQuantity: 10,
      modalOpen: false,
      listItemToModal: null,
      width: null,
      rate: null,
    };
  }

  async componentDidMount() {
    try {
      const rate = await getRate();
      this.setState({ rate });
    } catch (error) {
      console.error('Error fetching rate:', error);
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.updateWindowDimensions.bind(this),
    );
  }

  onChange(value) {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        ...value,
      },
    }));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  loadMoreItems() {
    this.setState(prevState => ({
      displayQuantity: prevState.displayQuantity + 10,
    }));
  }

  /**
   * Filter the list of tours
   */
  filterList() {
    const { list } = this.props;
    const { values } = this.state;
    const { DEPARTING, FROM, TYPE } = FIELDS;
    let filteredList = list;
    filteredList = filteredList.filter(i => i.available_seats !== 0);

    if (values[DEPARTING.ID]) {
      filteredList = filterToMostRecentNDays({
        daysCount: values[DEPARTING.ID],
        list: filteredList,
      });
    }

    if (values[FROM.ID]) {
      filteredList = filteredList.filter((i) => {
        if (i.reverse) {
          return i.tour_data.end_location === values[FROM.ID];
        }
        return i.tour_data.start_location === values[FROM.ID];
      });
    }

    if (values[TYPE.ID]) {
      filteredList = filteredList.filter(i => i.tour_type === values[TYPE.ID]);
    }

    return filteredList;
  }

  closeModal() {
    this.setState({
      modalOpen: false,
    });
  }

  displayMapModal(listItem) {
    this.setState({
      listItemToModal: listItem,
      modalOpen: true,
    });
  }

  render() {
    const {
      values,
      displayQuantity,
      modalOpen,
      width,
      listItemToModal,
      rate,
    } = this.state;

    const { list, loading } = this.props;
    const { DEPARTING, FROM, TYPE } = FIELDS;
    const Select = withFormGroup(FormSelect);
    const departLocationOptions = generateOptions(
      list.map((i) => {
        if (i.reverse) {
          return i.tour_data.end_location;
        }
        return i.tour_data.start_location;
      }),
    );

    const filteredList = this.filterList();

    const discountsList = filteredList
      .map((i) => {
        const idWithNoPrefix = i.id.replace('__LastDealDepartures__', '');
        const daysBeforeDeparture = (moment(i.date).diff(moment(), 'days') + 1);
        return (
          <React.Fragment key={i.id}>
            <div className="l-discounts-items-image">
              <img alt={i.tour_data.name} src={i.tour_data.main_image_url} className="" />
            </div>
            <div>
              <div className="l-discounts-items-name">{i.tour_data.name}</div>
              <Link
                to={`/${i.category_slug}/${i.tour_slug}`}
                className="l-discounts-items-viewLink"
              >
                View tour
              </Link>
            </div>
            <div>
              <div className="l-discounts-items-length">
                {i.tour_data.number_of_days} Days
              </div>
              <div className="l-discounts-items-range">
                {formatDate(i.date)} - {formatDate(i.end_date)}
              </div>
            </div>
            <div>
              <div className="l-discounts-items-fromTo">
                {i.reverse === true && (`${i.tour_data.end_location} to ${i.tour_data.start_location}`)}
                {i.reverse === false && (`${i.tour_data.start_location} to ${i.tour_data.end_location}`)}
              </div>
              <button
                type="button"
                onClick={() => this.displayMapModal(i)}
                className="l-discounts-items-viewMap"
              >
                View map
              </button>
            </div>
            <div>
              <div className="l-discounts-items-discountNumber">
                {numberToCurrency(calculatePriceWithRate(i.based_price - i.tour_price, rate))}
              </div>
              <div className="l-discounts-items-percentOff">
                {i.discount_rate}% off
              </div>
            </div>
            <div>
              <span className="l-discounts-items-basedPrice">
                {numberToCurrency(calculatePriceWithRate(i.based_price, rate))}
              </span>
              <span className="l-discounts-items-tourPrice">
                {numberToCurrency(calculatePriceWithRate(i.tour_price, rate))}
              </span>
            </div>
            <div>
              {daysBeforeDeparture > 30 ? (
                <Link
                  to={`${PAGES.BOOKINGS.PATH}?departureId=${idWithNoPrefix}`}
                  className="c-button c-button--border c-button--small"
                  style={{ alignSelf: 'stretch', marginBottom: 5 }}
                >
                  Book
                </Link>
              ) : (
                <Link
                  to={`${PAGES.LATE_REQUEST.PATH}`}
                  className="c-button c-button--border c-button--small"
                  style={{ alignSelf: 'stretch', marginBottom: 5 }}
                >
                  On Request
                </Link>
              )}
            </div>
          </React.Fragment>
        );
      })
      .slice(0, displayQuantity);

    const discountsListMobile = filteredList
      .map((i) => {
        const idWithNoPrefix = i.id.replace('__LastDealDepartures__', '');
        return (
          <Link
            key={i.id}
            to={`${PAGES.BOOKINGS.PATH}?departureId=${idWithNoPrefix}`}
            className="l-discounts-link-wrapper"
          >
            <div key={i.id} className="discounts-list-mobile">
              <div>
                <div
                  className="l-discounts-items-name"
                  style={{ marginBottom: 5 }}
                >
                  {i.tour_data.name}
                </div>
                <div className="l-discounts-items-length">
                  {i.tour_data.number_of_days} Days
                </div>
                <div className="l-discounts-items-range">
                  {formatDate(i.date)} – {formatDate(i.end_date)}
                </div>
              </div>
              <div className="l-discounts-link-wrapper-secondColumn">
                <div
                  className="l-discounts-items-percentOff"
                  style={{ marginBottom: 5 }}
                >
                  {i.discount_rate}% off
                </div>
                <div className="l-discounts-items-tourPrice">
                  {numberToCurrency(calculatePriceWithRate(i.tour_price, rate))}
                </div>
              </div>
            </div>
          </Link>
        );
      })
      .slice(0, displayQuantity);

    const headerList = (
      <>
        <div className="discount-header">Tour</div>
        <div className="discount-header">&nbsp;</div>
        <div className="discount-header">Length/Dates</div>
        <div className="discount-header">From/To</div>
        <div className="discount-header">Save</div>
        <div className="discount-header">Cost (NZD)</div>
        <div className="discount-header">&nbsp;</div>
      </>
    );

    return (
      <>
        <Banner
          backgroundImage="https://static-hakatours.imgix.net/tours-bg.jpg?fit=crop&auto=format"
        >
          <div className="bannerHeader">
            <h1 className="bannerHeader-headerMain">
              New Zealand Package Deals
            </h1>
            <div className="bannerHeader-headerSecondary">
              Save BIG on special offers including Last Minute and Early Bird departures. Hurry - these sell out fast!*
            </div>

            <div className="bannerHeader-selectors">
              <div>
                <div className="bannerHeader-selectors-caption">
                  Departing in
                </div>
                <Select
                  label="Departure options"
                  id={DEPARTING.ID}
                  name={DEPARTING.ID}
                  options={FIELDS.DEPARTING.OPTIONS}
                  value={values[DEPARTING.ID]}
                  onChange={this.onChange}
                  arrowColor="grey"
                  discounts="Discounts"
                  srOnly
                />
              </div>
              <div className="bannerHeader-selectors-divider">
                <div className="bannerHeader-selectors-divider-arrow">
                  <SVGArrow />
                </div>
              </div>
              <div>
                <div className="bannerHeader-selectors-caption">
                  Departing from
                </div>
                <Select
                  label="Departing from"
                  placeholder="All locations"
                  id={FROM.ID}
                  name={FROM.ID}
                  options={departLocationOptions}
                  value={values[FROM.ID]}
                  onChange={this.onChange}
                  arrowColor="grey"
                  discounts="Discounts"
                  srOnly
                />
              </div>
              <div className="bannerHeader-selectors-divider">
                <div className="bannerHeader-selectors-divider-arrow">
                  <SVGArrow />
                </div>
              </div>
              <div>
                <div className="bannerHeader-selectors-caption">Tour type</div>
                <Select
                  label="Tour type"
                  placeholder="All types"
                  id={TYPE.ID}
                  name={TYPE.ID}
                  options={FIELDS.TYPE.OPTIONS}
                  value={values[TYPE.ID]}
                  onChange={this.onChange}
                  arrowColor="grey"
                  discounts="Discounts"
                  srOnly
                />
              </div>
            </div>
          </div>
        </Banner>

        {modalOpen && (
          <Modal open={modalOpen} closeHandler={this.closeModal}>
            <div className="l-discounts-mapModal">
              <div className="l-discounts-mapModal-imageBox">
                <img
                  alt="map"
                  src={listItemToModal.tour_data.map_image_url}
                  className="l-discounts-mapModal-image"
                />
              </div>
              <div className="l-discounts-mapModal-caption">
                {listItemToModal.tour_data.name}
              </div>
            </div>
          </Modal>
        )}

        <div className="l-discounts">
          {filteredList.length > 0 && (
            <>
              <div className="l-discounts-list">
                {width > 768 && headerList}
                {width > 768 && discountsList}
              </div>
              {width <= 768 && discountsListMobile}
            </>
          )}

          {filteredList.length > 0 && filteredList.length > displayQuantity && (
            <div className="discounts-loadMore">
              <button
                type="button"
                className="c-button c-button--border c-button--small"
                onClick={this.loadMoreItems}
              >
                Load More
              </button>
            </div>
          )}
        </div>

        {!loading && !filteredList.length && list.length > 0 && (
          <div style={{ margin: 40 }}>
            <h4 className="c-heading c-heading--h4 u-text--center">
              No matching tours.
            </h4>
          </div>
        )}

        {!loading && list.length === 0 && (
          <div style={{ margin: 40 }}>
            <h4 className="c-heading c-heading--h4 u-text--center">
              There are no deals available right now, check back soon.
            </h4>
          </div>
        )}

        {loading && (
          <div style={{ margin: 40 }}>
            <h4 className="c-heading c-heading--h4 u-text--center">
              Loading ...
            </h4>
          </div>
        )}

        <p style={{ maxWidth: 1160, margin: '40px auto', fontStyle: 'italic' }}>
          *Terms and Conditions: Last Minute Deals available on selected departures only.
          Discounts cannot be applied to existing bookings or used in conjunction with any other offers.
          Discounts are subject to availability; may be withdrawn at any point without notice.
          Does not apply to pre- or post-tour accommodation, insurance, international airfare not specified in the itinerary,
          upgrades, add-ons, equipment rental, transfers, theme packs, or other in-country or on-board services.
          Full payment is required at least 56 days prior to travel. For full general T&Cs visit

          <a href="/booking-conditions/" style={{ marginLeft: 5 }}>Booking terms and conditions</a>
        </p>
      </>
    );
  }
}

DiscountsList.propTypes = {
  list: PropTypes.array.isRequired,
  loading: PropTypes.any.isRequired,
};

export default DiscountsList;
